@import '~bootstrap/dist/css/bootstrap.min.css';

$primaryColor: #E2AD0D;
$primaryTextColor: #5B3503;
$table-col-width: 200px;
$table-col-gap: 30px;

html, body {
  margin: 0;
  height: 100%;
  padding: 16px;
  text-align: center;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6, {
  margin: 0;
}

h2 {
  color: $primaryColor;
}

.column-5 {
  width: 50%;
  float: left;

  /* Force width to take into account border size */
  box-sizing: border-box;
}

.border-light {
  border: 1px solid black;
}

